<template>
  <div class="Demand">
    <div v-if="status == 1" class="green">挂售成功</div>
    <div class="red" v-else>挂售失败</div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class DemandSaleFlag extends Vue {
  @Prop() status;
}
</script>

<style lang="scss" scoped>
.Demand {
  font-size: 12px;
  .red {
    color: red;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .green {
    color: green;
  }
}
</style>
