<template>
  <div class="bgwrap">
    <div class="content">
      <div class="flex-column">
        <div style="font-weight: 800; text-align: center">
          什么是可挂售的游戏
        </div>
        <div style="font-size: 14px; margin-top: 15px">
          通过多种方式在应用内获取，处于未拆封状态下的游戏
        </div>
        <div style="font-size: 14px; margin-top: 15px">
          未兑换的实体商品暂时不支持挂售哦
        </div>
      </div>
      <div class="bottom">
        <div class="sure" @click="confirm">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      document.documentElement.style.overflowY = "scroll";
    },
  },
};
</script>
<style lang="scss" scoped>
.bgwrap {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba($color: #000000, $alpha: 0.8);
  width: 100%;
  height: 100%;
  z-index: 9999;

  .content {
    background: white;
    position: relative;
    top: 50%;
    left: 50%;
    height: 240px;
    transform: translate(-50%, -50%);
    width: 320px;
    border-radius: 30px;
    .flex-column {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 30px;
    }
    .bottom {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      .sure {
        width: 131px;
        height: 38px;
        background: black;
        color: white;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
      }
    }
  }
}
</style>
