<template>
  <div class="card">
    <div class="card-detail">
      <img :src="item.cover" alt="" :style="{width:wideHeight*0.29+'px',height:wideHeight*0.29+'px'}" />
      <div class="desc">
        <div class="name">{{ item.title }}</div>
        <div class="price">
          <div>
            在售最低价：{{
              item.sale_min_price == "0.00" ? "-" : `¥${item.sale_min_price}`
            }}
          </div>
          <div>
            求购最高价：{{
              item.entrust_max_price == "0.00"
                ? "-"
                : `¥${item.entrust_max_price}`
            }}
          </div>
        </div>
      </div>
      <div class="gosell" @click="toSale">去挂售</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import InteractionLib from "@/utils/interaction.lib";

@Component
export default class MineSell extends Vue {
  @Prop() item;
  wideHeight
 async created() {
    const {clientWidth} = document.body
    this.wideHeight = clientWidth
  }
  toSale() {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/hangsell?detail_id=${this.item.detail_id}`
      );
    }
    this.$router.push({
      name: "hangsell",
      query: {
        detail_id: this.item.detail_id,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: white;
  padding: 10px;
  border-radius: 8px;
  margin: 10px auto;
  .cancel {
    width: 59px;
    height: 28px;
    background: #ffffff;
    border-radius: 14px;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 0 83%;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #969696;
  }
  .card-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      object-fit: cover;
      border-radius: 4px;
    }
    .desc {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      height: 105px;
      justify-content: space-between;
      .name {
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: black;
        font-size: 16px;
      }

      .price {
        margin-top: 30px;
        margin-top: 10px;
        font-size: 13px;
        color: #969696;
        display: flex;
        flex-direction: column;
      }
    }
    .gosell {
      margin-top: 80px;
      width: 85px;
      height: 28px;
      background: #000000;
      border-radius: 14px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
</style>
