<template>
  <div>
    <TipsPop @confirm="confirm" v-if="firstSellState" />
    <div class="wrap intro" sticky>
      <div style="">
        <Tabs v-model="active" swipeable sticky color="black">
          <Tab title="挂售补给">
            <div class="tip">部分特殊卡券不支持挂售。</div>
            <div v-if="RepertoryList.length > 0" style="padding: 0 10px">
              <List
                v-model="loading0"
                :finished="finished0"
                finished-text="没有更多了"
                @load="onLoad0m"
                :immediate-check="false"
              >
                <MineSell
                  v-for="(item, index) in RepertoryList"
                  :key="index"
                  :item="item"
                />
              </List>
            </div>
            <div v-else style="height: 500px; position: relative">
              <EmptyBox tip="暂无可挂售的游戏" />
            </div>
          </Tab>
          <Tab title="正在挂售">
            <div v-if="RepertoryIng.length > 0" style="padding: 0 10px">
              <List
                v-model="loading1"
                :finished="finished1"
                finished-text="没有更多了"
                @load="onLoad1m"
                :immediate-check="false"
              >
                <Selling
                  v-for="(item, index) in RepertoryIng"
                  @delete="deleteSelling"
                  :key="index"
                  :items="item"
                />
              </List>
            </div>
            <div v-else style="height: 500px; position: relative">
              <EmptyBox tip="当前没有正在挂售的游戏" />
            </div>
          </Tab>
          <Tab title="挂售记录">
            <div v-if="SellRecordList.length > 0" style="padding: 0 10px">
              <List
                v-model="loading2"
                :finished="finished2"
                finished-text="没有更多了"
                @load="onLoad2m"
                :immediate-check="false"
              >
                <SellRecord
                  v-for="(item, index) in SellRecordList"
                  :key="index"
                  :items="item"
                />
              </List>
            </div>
            <div v-else style="height: 500px; position: relative">
              <EmptyBox tip="暂无挂售记录" />
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import { Tab, Tabs, List } from "vant";
import MineSell from "@/views/sell/components/MineSell.vue";
import Selling from "@/views/sell/components/Selling.vue";
import SellRecord from "@/views/sell/components/SellRecord.vue";
import EmptyBox from "@/components/EmptyBox.vue";
import InteractionLib from "@/utils/interaction.lib";
import TipsPop from "@/components/tipsPop.vue";
import bazaarApi from "@/api/bazaar.api";
import { observer } from "@/utils/common.util";
@Component({
  components: {
    Tab,
    Tabs,
    MineSell,
    EmptyBox,
    SellRecord,
    Selling,
    TipsPop,
    List,
  },
})
export default class Sell extends Vue {
  active = 0;
  firstSellState = true;
  RepertoryList = [];
  RepertoryIng = [];
  SellRecordList = [];
  finished0 = false;
  loading0 = false;
  finished1 = false;
  loading1 = false;
  finished2 = false;
  loading2 = false;
  page0 = 1;
  page1 = 1;
  page2 = 1;
  page_size = 10;
  async onLoad0m() {
    this.page0++;
    let res = await bazaarApi.getRepertory(this.page0, this.page_size);
    res.forEach((item) => {
      this.RepertoryList.push(item);
    });
    this.loading0 = false;
    if (res.length < 10) {
      this.finished0 = true;
    }
  }
  async onLoad1m() {
    this.page1++;
    let res = await bazaarApi.getRepertorying(this.page1, this.page_size);
    res.forEach((item) => {
      this.RepertoryIng.push(item);
    });
    this.loading1 = false;
    if (res.length < 10) {
      this.finished1 = true;
    }
  }
  async onLoad2m() {
    this.page2++;
    let res = await bazaarApi.getSellRecordList(this.page2, this.page_size);
    res.forEach((item) => {
      this.SellRecordList.push(item);
    });
    this.loading2 = false;
    if (res.length < 10) {
      this.finished2 = true;
    }
  }
  mounted() {
    observer("refreshInit", () => {
      this.init();
    });
  }
  async init(){
    if (this.$route.params.active) {
      this.active = this.$route.params.active;
    }
    let res = localStorage.getItem("firstSellState");
    if (res) {
      this.firstSellState = false;
    }
    this.RepertoryList = await bazaarApi.getRepertory();
    this.RepertoryIng = await bazaarApi.getRepertorying();
    this.SellRecordList = await bazaarApi.getSellRecordList();
    if(this.RepertoryList.length<10){
      this.finished0 = true
    }
    if(this.RepertoryIng.length<10){
      this.finished1 = true
    }
    if(this.SellRecordList.length<10){
      this.finished2 = true
    }
  }
  async created() {
    this.init()
  }
  confirm() {
    localStorage.setItem("firstSellState", true);
    this.firstSellState = false;
  }
  deleteSelling(id) {
      InteractionLib.updateGoldBlindBoxStatus()
    this.RepertoryIng = this.RepertoryIng.filter((item) => {
      if (item.id != id) {
        return item;
      }
    });
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  .desc {
    height: 128px;
    background: #f8f8f8;
    font-size: 13px;
    padding: 0 20px;
    font-weight: 400;
    color: #969696;
  }
}

.intro {
  ::v-deep {
    .van-sticky {
      background: white;
    }
    .van-tabs__wrap {
      background: white;
      .van-tabs__nav {
        width: 260px;
        .van-tab {
          font-size: 12px;
        }
        .van-tab--active {
          font-weight: 800;
        }
        .van-tabs__line {
          bottom: 19px !important;
          height: 2px;
          width: 15px !important;
        }
      }
    }
  }
}
.tip {
  padding: 8px 16px;
  background: #FFE2E5;
  font-size: 12px;
  font-weight: 400;
  color: #FF3648;
}
</style>
