<template>
  <div class="card">
    <div class="flex">
      <div class="order">
        <div>订单号 {{ items.order_no }}</div>
        <div class="copyorder" @click="copyorder">复制单号</div>
      </div>
      <div class="restDay" style="">
        {{ items.expire_time }}<span style="margin-left: 4px">完成</span>
      </div>
    </div>
    <div class="line"></div>
    <div class="card-detail">
      <img :src="items.cover" alt="" style="object-fit: cover"/>
      <div class="desc">
        <div class="name">{{ items.title }}</div>
        <div class="price">¥{{ items.trading_price }}</div>
      </div>
      <DemandFlag style="margin-right: 10px" :status="items.status" />

    </div>
    <div class="line"></div>
    <div class="bottom_flex">
        <div style="font-size:11px;color: #969696;">
          已转化为<span style="font-weight: 800;color:#F83737">{{items.trading_price}}蒸汽币</span>,存入钱包
        </div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import DemandFlag from "@/views/sell/components/DemandFlag.vue";
import { copy, momentFormat } from "@/utils/common.util";
import { Toast } from "vant"
@Component({
  components: {
    DemandFlag,
  },
})
export default class SellRecord extends Vue {
  @Prop() items;
  created() {
    this.items.expire_time = momentFormat(this.items.create_time);
    this.order = this.items.order_no;
    this.items.order_no =
      this.items.order_no.slice(0, 3) + "***" + this.items.order_no.slice(-4);
  }
  copyorder() {
    copy(this.order);
    Toast.success('复制成功')
  }
}
</script>

<style lang="scss" scoped>
.restDay {
      font-size: 11px;
      color: #969696;
    }
.card {
  background: white;
  border-radius: 8px;
  margin: 10px auto;

  .bottom_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    
    .cancel {
      width: 59px;
      height: 28px;
      background: #ffffff;
      border-radius: 14px;
      border: 1px solid #dddddd;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background: #f2f2f2;
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .order {
      font-size: 12px;
      color: #969696;
      display: flex;
      align-items: center;
      .copyorder {
        width: 58px;
        height: 18px;
        background: #ecedee;
        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        margin-left: 5px;
        font-size: 10px;
      }
    }
    .ing {
      color: black;
      font-size: 12px;
      font-weight: 800;
    }
  }
  .card-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    img {
      width: 63px;
      height: 63px;
      border-radius: 4px;
    }
    .desc {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      margin-left: 10px;
      .name {
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /* 超出部分显示省略号 */
        white-space: nowrap; /*规定段落中的文本不进行换行 */
        width: 180px; /*需要配合宽度来使用*/
        color: black;
        font-size: 16px;
        font-weight: 800;
      }
      .price {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 800;
      }
    }
  }
}
</style>
