<template>
  <div class="card">
    <div class="card-detail">
      <img :src="items.cover" alt="" :style="{width:wideHeight*0.29+'px',height:wideHeight*0.29+'px'}"/>
      <div class="desc">
        <div class="name">{{ items.title }}</div>
        <div class="price">
          <div>在售最低价：¥{{ items.sale_min_price }}</div>
          <div>
            <span>我的挂售价:</span>
            <span style="color: red; margin-left: 10px"
              >¥{{ items.sale_mine_price }}</span
            >
          </div>
        </div>
      </div>
      <div class="cancel" @click="cancel">取消</div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from "vue-property-decorator";
import bazaarApi from "@/api/bazaar.api";
import { Toast } from "vant";
@Component({
  components: {
    Toast
  },
})
export default class DetailCard extends Vue {
  @Prop() items;
  wideHeight = 0
  async created() {
    const {clientWidth} = document.body
    this.wideHeight = clientWidth
  }
  async cancel() {
    await bazaarApi
      .cancelRepertorying(this.items.id)
      .then(() => {
        Toast.success("取消成功");
        this.$emit("delete", this.items.id);
      })
      .catch((err) => Toast.fail({ message: err.message }));
  }
}
</script>

<style lang="scss" scoped>
.card {
  background: white;
  padding: 10px;
  border-radius: 8px;
  margin: 10px auto;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #969696;
  }
  .card-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      border-radius: 4px;
      object-fit: cover
    }
    .desc {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      height: 105px;
      justify-content: space-between;
      .name {
        color: black;
        font-size: 16px;
        width: 140px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .price {
        margin-top: 30px;
        margin-top: 10px;
        font-size: 13px;
        color: #969696;
        display: flex;
        flex-direction: column;
      }
    }
    .cancel {
      margin-top: 80px;
      width: 65px;
      height: 28px;
      background: white;
      border-radius: 14px;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      border: 1px solid #dddddd;
    }
  }
}
</style>
